import { useState } from "react";
import axios from "axios";
import PageContainerLayout from "../../layout/PageContainerLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";

const CreateProgram = () => {
  const id = useParams().id as string;
  const { authorization: { access_token } } = useSelector((store:RootState) => store.authStore);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    duration: "",
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage("");
    setErrorMessage("");

    const baseUrl = process.env.REACT_APP_BACKEND_API;

    try {
      const response = await axios.post(
        `${baseUrl}/admin/program/${id}/create`,
        {
          name: formData.name,
          description: formData.description,
          duration: parseInt(formData.duration, 10),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization:  `Bearer ${access_token}`, // Add token to your .env file
          },
          maxBodyLength: Infinity,
        }
      );

      toast.success("Program created successfully!");
      setFormData({ name: "", description: "", duration: "" });
    } catch (error: any) {
      const errorMsg =
        error?.response?.data?.message || "Failed to create the program.";
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        <PageContainerLayout>
        <div className="bg-grey-600 px-5">
            <div className="w-full flex items-center justify-between px-4 py-5 my-10">
                <button onClick={() => navigate(-1)} className="bg-white text-primary focus:outline-none rounded px-4 py-3 w-[100px]">
                    Back
                </button>
            </div>
            <div className="flex flex-col items-center justify-center h-auto bg-gray-50">
              <div className="w-full bg-white rounded-lg shadow-md p-6">
                  <h2 className="text-lg font-bold text-gray-800 mb-4 text-center">
                  Create a New Program
                  </h2>

                  {successMessage && (
                  <div className="mb-4 text-green-500 font-medium">
                      {successMessage}
                  </div>
                  )}
                  {errorMessage && (
                  <div className="mb-4 text-red-500 font-medium">{errorMessage}</div>
                  )}

                  <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                      <label
                      htmlFor="name"
                      className="block text-gray-700 font-medium mb-2"
                      >
                      Program Name
                      </label>
                      <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      />
                  </div>

                  <div className="mb-4">
                      <label
                      htmlFor="description"
                      className="block text-gray-700 font-medium mb-2"
                      >
                      Description
                      </label>
                      <textarea
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      rows={4}
                      />
                  </div>

                  <div className="mb-4">
                      <label
                      htmlFor="duration"
                      className="block text-gray-700 font-medium mb-2"
                      >
                      Duration (in months)
                      </label>
                      <input
                      type="number"
                      id="duration"
                      name="duration"
                      value={formData.duration}
                      onChange={handleChange}
                      required
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      />
                  </div>

                  <button
                      type="submit"
                      className="w-auto mx-auto py-4 px-4 bg-primary text-white font-medium rounded-lg shadow"
                      disabled={loading}
                  >
                      {loading ? "Creating Program..." : "Create Program"}
                  </button>
                  </form>
              </div>
            </div>
        </div>
        </PageContainerLayout>
    </>
  );
};

export default CreateProgram;
