import React, { useState } from "react";
import axios from "axios";
import PageContainerLayout from "../../layout/PageContainerLayout";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { capitalizeAllFirstLetters } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { toast } from "react-toastify";
const URL = process.env.REACT_APP_BACKEND_API

const CreateRoleForm = () => {
  const {authorization:{access_token}} = useSelector((store:RootState) => store.authStore)
  const navigate = useNavigate()
  const [formData, setFormData] = useState<any>({
    name: "",
    user_read: "NO",
    user_write: "NO",
    school_read: "NO",
    school_write: "NO",
    hotel_read: "NO",
    hotel_write: "NO",
    visa_applications_read: "NO",
    visa_applications_write: "NO",
    flight_booking_read: "NO",
    flight_booking_write: "NO",
    school_application_read: "NO",
    school_application_write: "NO",
    admin_read: "NO",
    admin_write: "NO",
    settings_read: "NO",
    settings_write: "NO",
  });

  const [loading, setLoading] = useState(false);

  // Handle form input change
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${URL}/admin/setting/role/create-new`, // Replace with actual base URL
        headers: {
          "Content-Type": "application/json",
          Authorization:  `Bearer ${access_token}`
        },
        data: JSON.stringify(formData),
      };

      const response = await axios(config);
      toast.success("Role created successfully!")
      console.log("Response:", response.data);
      setFormData({
        name: "",
        user_read: "NO",
        user_write: "NO",
        school_read: "NO",
        school_write: "NO",
        hotel_read: "NO",
        hotel_write: "NO",
        visa_applications_read: "NO",
        visa_applications_write: "NO",
        flight_booking_read: "NO",
        flight_booking_write: "NO",
        school_application_read: "NO",
        school_application_write: "NO",
        admin_read: "NO",
        admin_write: "NO",
        settings_read: "NO",
        settings_write: "NO",
      });
    } catch (error) {
      console.error("Error creating role:", error);
      toast.error("Failed to create role. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainerLayout>
         <div className="pt-[22px]">
            <div className='w-full h-[80px] flex items-center justify-between bg-white px-[30px] py-[19px]'>
                <button onClick={() => navigate(-1)} className='flex items-center gap-[11px] text-black py-[17px] px-5 rounded-[4px]'>
                    <FiChevronLeft className='text-lg' /> Back
                </button>
            </div>
        </div>
        <div className="p-6 max-w-3xl mx-auto bg-white rounded shadow my-6">
        <h1 className="text-lg text-primary mb-4">Create New Role</h1>
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Role Name
            </label>
            <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 p-2 border rounded w-full"
                required
            />
            </div>

            <h2 className="text-lg font-semibold mb-2">Permissions</h2>
            {Object.keys(formData)
            .filter((key) => key !== "name")
            .map((key) => (
                <div key={key} className="mb-4">
                <label htmlFor={key} className="block text-sm font-medium text-gray-700">
                    {capitalizeAllFirstLetters(key.replace(/_/g, " "))}
                </label>
                <select
                    id={key}
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    className="mt-1 p-2 border rounded w-full"
                >
                    <option value="NO">NO</option>
                    <option value="YES">YES</option>
                </select>
                </div>
            ))}

            <button
            type="submit"
            className={`px-4 py-2 rounded h-[7vh] ${
                loading ? "bg-gray-300" : "bg-primary text-white"
            }`}
            disabled={loading}
            >
            {loading ? "Creating..." : "Create Role"}
            </button>
        </form>
        </div>
    </PageContainerLayout>
  );
};

export default CreateRoleForm;
